.defaultText{
  font-family: "Roboto";
  font-weight: 400;
}
.black{
  color: black;
}
.grey1{
color: var(--color-grey-light-1)
}
.grey3{
  color: var(--color-grey-dark-2)
}
.green1{
  color: var(--color-green)
}
.blue3{
  color: var(--blue-3)
}
.red1{
  color: var(--red-1)
}
.yellow1{
  color: var(--yellow-1);
}

.xs{
  font-size: 8px;
}
.s{
  font-size: 12px;
}
.m{
  font-size: 16px;
}
.l{
  font-size: 20px;
}
.xl{
  font-size: 30px;
}