.some{
  background-color: gray;
  display: "flex";
   height: "100%";
   width: 200px;
}

.growerPaper{
  margin-bottom: 15px;
  padding: 10px;
}

.growerPaper:hover{
  cursor: pointer;
}

.buttonField{
  margin: 8px;
}

.selectedGrower{
  border: 1px solid #2196F3;
}

.inspectionPaper{
  margin-bottom: 15px;
  padding: 5px;
  border-left: 2px solid var(--color-green-1);
}

.listItem{
  border-bottom:1px solid var(--color-grey-light-3);
  
}

.listItem:hover{
  cursor: pointer;
  background-color: var(--color-green-1);
  color: white;
}

.listItemActive{
  background-color: var(--color-green-1);
  color: white;
  border-radius: 5px;
}
