
.img_card {

  width: 50%;
  height: 100%;
  margin-inline: 25%;
}
.img_card_landscape{
  width:100%;
  height: 100%;
}

@media (max-width: 700px) {
    .img_card_landscape {
        height: 50%;
        margin-top: 25%;
    }
}

@media (max-width: 700px) {
  .img_card {
      width: 100%;
      margin: 0;
  }
}
