.item{
  background-color: var(--color-grey-light-2);
  padding: 10px;
}

.item:hover{
  cursor: pointer;
  background-color: var(--color-green-1);
  color: white;
}

.activeItem{
  background-color: var(--color-green-1);
  color: white;
}