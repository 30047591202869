.fullManifoldcontainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-green); */
  align-items: center;
  /* justify-content: center; */
}

.manifoldTitle{
  margin-bottom: 2rem;
}

.manifoldPictures{
  padding-top: 4rem;
  width: 100%;
}

.img_container{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4rem;
}

.mySwiper {
  width: 90%;
  height: 90%;
  max-width: 100%;
}

.swiper-slide {
  width: 100%;
}

.swiperContent {
  width: 100%;
  height: 100%;
  background-color: white;
}