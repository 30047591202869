.Screen{
  width: 100%;
  height: 100%;
  display: flex;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.noPad{
  padding: 0;
}
.mPad{
  padding: 20px;
}